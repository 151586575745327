<template>
  <form @submit.prevent="onSubmit()">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{
            name: 'supplierContacts',
            params: {
              fId: supplierId
            }
          }"
        />
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>

          <div class="grid">
            <div class="tmp-col">
              <Input label="Nom" id="nom" required />
            </div>
            <div class="tmp-col">
              <Input label="Prénom" id="prenom" required />
            </div>
            <div class="tmp-col">
              <Input label="Fonction" id="fonction" />
            </div>
            <div class="tmp-col">
              <Input label="Email" type="email" id="email" required />
            </div>
            <div class="tmp-col">
              <div class="input-block">
                <Input label="Téléphone fixe" type="tel" id="telephonefixe" />
              </div>
            </div>
            <div class="tmp-col">
              <div class="input-block">
                <Input label="Téléphone portable" type="tel" id="telephoneportable" />
              </div>
            </div>
            <div class="tmp-col">
              <Input label="Téléphone entreprise" type="tel" id="telephoneentreprise" />
            </div>
            <div class="col--fullwidth">
              <Input label="Remarques" type="textarea" id="remarques" />
            </div>
            <div class="tmp-col">
              <Radio
                id="actif"
                required
                :items="[
                    { label: 'Oui', value: true },
                    { label: 'Non', value: false },
                  ]"
                label="Actif"
                inputStyle="inline"
              />
            </div>
          </div>

        </Container>
      </Section>
    </div>

    <div class="view-footer">
      <div class="view-actions view-actions--left">
        <Btn
          text="Annuler"
          :to="{
            name: 'supplierContacts',
            params: {
              fId: supplierId
            }
          }"
        />
        <Btn
          v-if="action === 'edit' && helperService.userHasPermission('fournisseurcontact_oremove')"
          text="Supprimer"
          @click="modalActive = true"
        />
        <Btn
          v-if="helperService.userHasPermission('fournisseurcontact_oadd')"
          btnType="submit"
          :text="action === 'edit' ? 'Modifier' : 'Sauvegarder'"
          color="primary"
        />
      </div>
    </div>

  </form>

  <!-- Modals modalDelete-->
  <Modal
    title="Supprimer le fournisseur ?"
    :active="modalActive"
    @modal-close="modalActive = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer le fournisseur <b>{{ supplier.nom }}</b>&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.deleteItem = false" />
      <Btn
        text="Supprimer"
        @click="deleteSupplierContact()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'
import Radio from '@/components/form/Radio.vue'

export default {
  name: 'SupplierContactAddEditView',

  components: {
    Radio,
    Modal,
    Btn,
    Input,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      nom: this.yup.string().required(),
      prenom: this.yup.string().required(),
      fonction: this.yup.string().nullable(),
      email: this.yup.string().required(),
      telephonefixe: this.yup.string().nullable(),
      telephoneportable: this.yup.string().nullable(),
      telephoneentreprise: this.yup.string().nullable(),
      remarques: this.yup.string().nullable(),
      actif: this.yup.boolean().optional().default(true),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      if (this.action === 'add') {
        this.createSupplierContact(values)
      } else {
        this.updateSupplierContact(values)
      }
    })

    return {
      supplier: [],
      supplierId: this.$route.params.fId ?? null,
      contactId: this.$route.params.cId ?? null,
      action: this.$route.params.action,
      data: [],
      modalActive: false,
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  created() {
    if (this.$route.params.cId) {
      this.getSupplierContact()
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  methods: {
    createSupplierContact(values) {
      this.fetchService.post(`fournisseur/${this.supplierId}/contact`, values).then(() => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le contact fournisseur a bien été crée.',
        })

        this.$router.push({ name: 'supplierContacts', params: { fId: this.supplierId } })
      },
      (responseError) => {
        this.emitter.emit('alert', {
          type: 'error',
          content: responseError.data,
        })
      })
    },

    updateSupplierContact(values) {
      this.fetchService.put(`fournisseur/${this.supplierId}/contact/${this.contactId}`, values)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le contact fournisseur a bien été modifié.',
          })

          this.$router.push({ name: 'supplierContacts', params: { fId: this.supplierId } })
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
        })
    },

    deleteSupplierContact() {
      this.fetchService.delete(`fournisseur/${this.supplierId}/contact/${this.contactId}`)
        .then(() => {
          this.modalActive = false

          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le contact fournisseur a bien été supprimé.',
          })

          this.$router.push({ name: 'supplierContacts', params: { fId: this.supplierId } })
        })
    },

    getSupplierContact() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`fournisseur/${this.supplierId}/contact/${this.contactId}`)
        .then((response) => {
          this.supplierContact = response.data

          this.schema = this.formService.populateShema(this.supplierContact)

          this.formService.setFormValues(this.schema)
          this.emitter.emit('close-loader')
        })
    },
  },
}
</script>

<style scoped>

</style>
